<template>
    <v-dialog v-model="dialog" width="600px" overlay-opacity="0.95">
        <template v-slot:activator="{ on }">
            <slot name="button" v-bind:on="on">
                <v-btn text v-on="on">Contact Us</v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Contact Us</span>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container grid-list-md>
                    <v-form v-model="valid">
                        <v-text-field class="mb-3" color="secondary" outlined v-model="firstName" label="First Name" ref="hidden" v-show="false" />
                        <v-text-field class="mb-3" color="secondary" outlined v-model="name" :rules="inputRules" label="Name" required />
                        <v-text-field class="mb-3" color="secondary" outlined v-model="email" :rules="emailRules" label="E-mail" required />
                        <v-text-field class="mb-3" color="secondary" outlined v-model="subject" :rules="inputRules" label="Subject" required />
                        <v-textarea class="mb-3" color="secondary" outlined v-model="message" :rules="messageRules" label="Message" required />
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="send" :disabled="!valid">
                    Send <v-icon right small>{{ mdiEmailCheck }}</v-icon>
                </v-btn>
                <v-btn color="error" text @click="dialog = false">
                    Cancel <v-icon right small>{{ mdiCancel }}</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiEmailCheck, mdiCancel } from "@mdi/js";
import { TRIGGER_NOTIFICATION } from "../store/actions.type";
import ApiService from "@/common/api.service";

export default {
    name: "Contact",

    data() {
        return {
            mdiEmailCheck: mdiEmailCheck,
            mdiCancel: mdiCancel,
            dialog: false,
            valid: false,
            firstName: "",
            name: "",
            email: "",
            emailRules: [
                v => !!v || "E-mail is required",
                v => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            subject: "",
            inputRules: [v => !!v || "Field is required"],
            message: "",
            messageRules: [
                v => !!v || "Message is required",
                v => v.length > 10 || "Please enter at least 10 characters"
            ]
        };
    },

    methods: {
        async send() {
            this.dialog = false;
            var { data } = await ApiService.post("message", {
                firstName: this.firstName,
                name: this.name,
                email: this.email,
                subject: this.subject,
                message: this.message
            });
            this.$store.dispatch(TRIGGER_NOTIFICATION, {
                message: data.message,
                type: "success",
                timeout: 3000
            });
            this.firstName = "";
            this.name = "";
            this.email = "";
            this.subject = "";
            this.message = "";
        }
    }
};
</script>

<style>
</style>
